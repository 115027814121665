import React from "react";
import CompImage from "../common/comp-image";

const VillageAdministrationBody = (props) => {
  return (
    <div className="min-vh-100">
      <div className="mw8 serif center pa2">
        <div className="serif f3 lh-copy tc mv5">{props.title}</div>
        <CompImage image={props.image} />
      </div>
    </div>
  );
};

export default VillageAdministrationBody;
