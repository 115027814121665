import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/common/layout";
import GovernmentNavbar from "../components/government/government-navbar";
import VillageAdministrationBody from "../components/government/village-administration-body";

export const query = graphql`
  query VillageAdministrationQuery {
    administration: sanityVillageAdministration {
      id
      title
      mainImage {
        asset {
          id
        }
        caption
        alt
      }
    }
  }
`;

const VillageGovernmentPage = (props) => {
  const { data } = props;

  const administration = (data || {}).administration;

  return (
    <Layout>
      <GovernmentNavbar />
      <VillageAdministrationBody
        title={administration.title}
        image={administration.mainImage}
      />
    </Layout>
  );
};

export default VillageGovernmentPage;
