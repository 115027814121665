import React from "react";
import Img from "gatsby-image";

const CompImage = (props) => {
  return (
    <section>
      {props.image && (
        <div className="w-100 mw8 h-100 mv5">
          <Img
            className="mb3"
            fluid={props.image.asset.fluid}
            alt={props.image.alt}
          />
          <p className="f5 tc i lh-copy">{props.image.caption}</p>
        </div>
      )}
    </section>
  );
};

export default CompImage;
